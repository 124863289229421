<template>
    <v-container fluid class="ma-0 pa-0">
        <v-card outlined elevation="0">
            <v-card-title class="headline">
                {{ $t('erp.lang_createUnit') }}
            </v-card-title>
            <v-divider class="pa-0 ma-0"/>
            <v-card-text>
                <v-form ref="form" v-model="valid">
                    <v-row>
                        <v-col lg="4" md="6" sm="6">
                            <v-text-field outlined
                                          @focus="showTouchKeyboard"
                                          :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                          v-model="unit.name"
                                          :label="$t('erp.lang_unitName')"
                                          :rules="[v => !!v || $t('generic.lang_requiredField')]"
                                          autocomplete="off"
                                          required
                            ></v-text-field>
                        </v-col>
                        <v-col lg="4" md="6" sm="6">
                            <v-text-field outlined
                                          @focus="showTouchKeyboard"
                                          :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                          v-model="unit.symbol"
                                          :label="$t('erp.lang_unitSymbol')"
                                          :rules="[v => !!v || $t('generic.lang_requiredField')]"
                                          autocomplete="off"
                                          required
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters justify="space-between">
                        <v-col cols="12">
                            <v-divider class="pa-0 ma-0"/>
                        </v-col>
                        <v-col cols="12">
                            <v-subheader class="pa-0 ma-0">{{ $t('erp.lang_conversionMainUnit') }}</v-subheader>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col lg="4" md="6" sm="6">
                            <v-select outlined
                                      :label="$t('erp.lang_mainUnit')"
                                      :items="filteredMainUnits"
                                      item-text="unit_name"
                                      @change="handle"
                                      item-value="id"
                                      v-model="mainUnit.id"
                            ></v-select>
                        </v-col>
                        <v-col lg="4" md="6" sm="6">
                            <v-text-field outlined
                                          @focus="showTouchKeyboard"
                                          :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                          v-model="mainUnit.value"
                                          :label="$t('erp.lang_mainUnitValue')"
                                          :disabled="(mainUnit.id===0)?true:false"
                                          autocomplete="off"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col cols="12" align="right">
                            <v-btn color="primary"
                                   :loading="loading"
                                   class="ma-0"
                                   large
                                   @click="createUnit()"
                                   :disabled="!valid"
                            >
                                {{ $t('generic.lang_add') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
        <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
            <vue-touch-keyboard id="onScreenKeyboard" class="internalWidth" :options="touchKeyboard.options"
                                :defaultKeySet="touchKeyboard.keySet" v-if="touchKeyboard.visible"
                                :layout="touchKeyboard.layout" :cancel="hideTouchKeyboard"
                                :accept="hideTouchKeyboard" :input="touchKeyboard.input"/>
        </div>
    </v-container>
</template>

<script>
import {Events} from "../../../../plugins/events";
import mixin from "../../../../mixins/KeyboardMixIns"

export default {
    name: "UnitsCreateComponent",

    components: {},

    mixins: [mixin],

    data() {
        return {
            loading: false,
            valid: false,
            mainUnits: [],
            unit: {
                name: "",
                symbol: "",
            },
            mainUnit: {
                id: 0,
                value: 1,
            },
        }
    },

    computed: {
        filteredMainUnits() {
          if(!this.mainUnits || !this.mainUnits.length){
            return [];
          }
          let filtered=[];
             filtered=this.mainUnits.map( unit => {
                if(!unit && unit.unit_name.length === 0)
                    return;

                if(unit.unit_name.includes("lang_"))
                    unit.unit_name = this.$t('erp.'+unit.unit_name);

                return unit;
            })
          return filtered;
        }
    },

    methods: {
        getMainUnits() {
          let self=this;
            this.axios.post("get/erp/units/")
                .then(res => {
                    if (res.data.STATUS ==='SUCCESS' ) {
                      self.mainUnits=[];
                      self.mainUnits.push({
                            id: 0,
                            unit_name:  self.$t('generic.lang_setAsMain')
                        });

                      self.mainUnits.push(...res.data.units);
                    }else{
                      Events.$emit("showSnackbar", {
                        message: self.$t('erp.lang_errorWhileGettingMainUnits'),
                        color: "error"
                      });
                    }
                }).catch(err=>{
              Events.$emit("showSnackbar", {
                message: err.message,
                color: "error"
              });
            })
        },
        createUnit() {
            if (this.$refs.form.validate()) {
                this.loading = true;
                this.axios.post("/create/erp/unit/", {
                    unitName: this.unit.name,
                    unitSymbol: this.unit.symbol,
                    mainUnitID: this.mainUnit.id,
                    mainUnitValue: this.mainUnit.value,
                }).then(res => {
                    if (res.status === 200) {

                        this.$refs.form.reset();
                        this.getMainUnits();

                        Events.$emit("showSnackbar", {
                            message: this.$t('generic.lang_ActionSuccessful'),
                            color: "success"
                        });
                    } else {
                        Events.$emit("showSnackbar", {
                            message: this.$t('generic.lang_anErrorOccurredPLSTryAgain'),
                            color: "error"
                        });
                    }
                    this.loading = false;
                }).catch(err => {
                    Events.$emit("showSnackbar", {
                        message: this.$t('generic.lang_error'),
                        color: "error"
                    });
                    this.loading = false;
                });
            }

        },
        handle() {
            if (this.mainUnit.id === 0) {
                this.mainUnit.value = 1;
            }
        }
    },
    mounted() {
        this.getMainUnits();
    }
}
</script>

<style scoped>

</style>